@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
}

.content {
  @include mixins.content($main-black);

  margin-top: 50px;
  margin-bottom: 150px;

  @media only screen and (min-width:765px) {
    margin-top: 50px;
    margin-bottom: 400px;
  }
}

.title {
  @include mixins.title(0, #fff);
  max-width: 60%;
  align-self: flex-start;

  // @media only screen and (min-width:765px) {
  //   margin-top: 92px;
  // }

  // @media only screen and (min-width: 1108px) {
  //   margin-top: 140px;
  // }
}

.subtitle {
  color: #fff;
  font-size: 20px;

  font-weight: 700;
  line-height: 29px;
  text-align: left;
  align-self: flex-start;
  margin-top: 40px;
  margin-bottom: 0;



  @media only screen and (min-width:886px) {
    font-size: 25px;
  }
}

.text {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  align-self: flex-start;
  margin: 15px 0 0 0;

  @media only screen and (min-width:765px) {
    font-size: 18px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.link {
  color: #fff;
}