@import './variables';

@mixin button-with-icon($width, $height, $url) {
  background-color: transparent;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
  min-height: $height;
  background-image: url($url);
  cursor: pointer;
}

@mixin button($mt, $mb: 53px) {
  background-color: $main-blue;
  padding: 18.5px 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  border-radius: 5px;
  letter-spacing: -0.02em;
  color: #fff;
  margin-top: $mt;
  margin-bottom: $mb;
  border: none;
  cursor: pointer;
  transition: background-color .2s ease-in-out;

  &:hover,
  &:active {
    background-color: $main-blue-dark;
  }

  @media only screen and (min-width: 765px) {
    font-size: 20px;
    line-height: 23px;
  }
}

@mixin title($margin-top, $color: $main-black) {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
  margin-top: $margin-top;
  text-transform: uppercase;
  text-align: left;
  color: $color;
  letter-spacing: -0.02em;
  align-self: flex-start;


  @media only screen and (min-width:765px) {
    font-size: 35px;
    line-height: 41px;
    max-width: 485px
  }

}

@mixin content($bgc: #fff) {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  padding: 0 15px;
  // margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  background-color: $bgc;
  max-width: 1440px;
  min-width: 320px;

  @media only screen and (min-width: 765px) {
    padding: 0 65px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 0 103px;
  }
}

@mixin container($bgc: #fff) {
  display: flex;
  background-color: $bgc;
  justify-content: center;
  flex-direction: column;
}

@mixin accent {
  color: $main-orange
}

@mixin text($mt, $mb, $clr: $main-black) {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  margin-top: $mt;
  margin-bottom: $mb;
  text-align: left;
  color: $clr;

  @media only screen and (min-width: 765px) {
    line-height: 25px;
  }
}