@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container();
}

.content {
  @include mixins.content();
  margin-bottom: 49px;

  @media only screen and (min-width: 765px) {
    margin-bottom: 131px;
  }

  @media only screen and (min-width: 1108px) {
    margin-bottom: 140px;
  }
}

.title {
  @include mixins.title(48px, $main-black);
  width: 100%;
  max-width: unset !important;
  margin-bottom: 20px;
  text-align: left;

  @media only screen and (min-width:765px) {
    margin-top: 97px;
    font-size: 40px;
    line-height: 46.8px;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 1108px) {
    text-align: center;
    margin-top: 140px;
    margin-bottom: 50px;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  // grid-template-rows: repeat(3, auto);
  width: 100%;
  justify-content: center;
  gap: 20px;

  @media only screen and (min-width: 1108px) {
    gap: 39px;
  }
}

.accent {
  @include mixins.accent
}

.card {
  padding: 38px 0 50px 0;
  box-sizing: border-box;
  border-radius: 10px;
  background: linear-gradient(156.71deg, #3934EA 1.43%, #2D28CE 40.2%, #6360CA 99.47%);
  display: flex;
  flex-direction: column;
  max-height: 250px;
  height: 100%;
  max-width: 100%;
  min-width: 290px;

  &_white {
    background: #fff;
    border: 1px solid $main-blue;
    padding: 20px 9px 20px 10px;
    display: flex;
    justify-content: space-between;


    @media only screen and (min-width: 630px) {
      grid-column: 1/3;
      // padding-top: 40px;
    }

    @media only screen and (min-width: 765px) {
      min-height: 216px;
      // grid-column: 1/3;
    }

    // @media only screen and (max-width: 920px) {
    //   grid-column: 1;
    // }

    @media only screen and (min-width: 1108px) {
      // padding-top: 42px;
      grid-row: unset;
      grid-column: unset;
      min-height: 250px;
    }
  }

}

.cardText {
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0;

  &_black {
    color: $main-black;
  }

  &_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.02em;

    &_sim {

      @media only screen and (min-width: 630px) {
        margin-top: 14px;
      }

      @media only screen and (min-width: 765px) {
        font-size: 30px;
        letter-spacing: -0.04em;
      }

      @media only screen and (min-width: 1108px) {
        font-size: 30px !important;
        letter-spacing: -0.04em !important;

      }
    }

    @media only screen and (min-width:765px) {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;
    }
  }

  &_device {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
    margin-top: 9px;

    @media only screen and (min-width:765px) {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;
    }
  }

  &_price_erp {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin-top: 21px;

    @media only screen and (min-width: 1108px) {
      font-size: 35px;
    }
  }

  &_price_sim {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin-top: 28px;

    @media only screen and (min-width: 1108px) {
      font-size: 35px;
    }
  }

  &_period {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
  }

  &_erp {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-top: 19px;
  }

  &_sim {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-top: 28px;
  }

  &_note {
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    margin-top: 8px;
  }
}