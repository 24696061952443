@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
  overflow: hidden;
}

.content {
  @include mixins.content($main-black);
  // padding: 0 !important;
  overflow: hidden;
  margin-bottom: 38px;

  @media only screen and (min-width:765px) {
    margin-bottom: 96px;
  }

  @media only screen and (min-width:1108px) {
    margin-bottom: 113px;
  }
}

.title {
  @include mixins.title(48px, #fff);

  @media only screen and (min-width:765px) {
    // padding-left: 65px;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    margin-top: 100px;
  }

  @media only screen and (min-width: 1108px) {
    padding-left: 103px;
    margin-top: 107px;
    text-align: center;
    max-width: 770px;
    align-self: center;
    padding-left: 0;
  }
}

.accent {
  @include mixins.accent
}

.types {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: end;
  // margin-top: 90px;
  column-gap: 20px;
  row-gap: 104px;
  box-sizing: border-box;
  width: 100%;
  // margin-bottom: 50px;
  // padding: 0 103px;
  // margin: 80px 103px 198px;
  margin: 91px 0 0;
  justify-content: space-between;
  display: none;

  @media only screen and (min-width:1108px) {
    display: flex;
  }
}

.type {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 435px;
  height: 100%;
  align-items: center;
}

.typeImage {
  margin-top: auto;
  width: 320px;
  pointer-events: none;
}

.typeText {
  text-align: center;
  color: #fff;
  max-width: 100%;
  letter-spacing: normal;
  line-height: 22px;
  margin-top: 48px;
  font-size: 20px;
}

.button {
  background-image: url(../../assets/icons/arrowRight.svg);
  width: 8px;
  height: 20px;
  border: none;
  background-color: transparent;

  &_next {
    transform: rotateY(180deg);
  }
}

.swiperContainer {
  min-width: 0;
  // display: flex;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  @media only screen and (min-width:1108px) {
    display: none;
  }
}

.swiper {
  // width: 100%;
  margin-top: 88px;

  @media only screen and (min-width:765px) {
    margin-top: 44px;
  }

}

.slide {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  // height: 285px !important;
  align-items: center;
  align-self: flex-end;
  padding-bottom: 66px;
  // width: 200px !important;

  @media only screen and (min-width:765px) {
    // width: 300px !important;
  }
}

.slideImageContainer {
  width: 195px !important;


  @media only screen and (min-width:765px) {
    width: 303px !important;
  }
}

.slideImage {
  margin-top: auto;
  width: 100%;
  pointer-events: none;
  margin-bottom: 0;

  @media only screen and (min-width:765px) {
    width: 303px;
  }

  // @media only screen and (min-width: 1108px) {
  //   width: 320px;
  //   // height: 364px;
  // }
}

.sliderText {
  text-align: center;
  color: #fff;
  letter-spacing: normal;
  margin-top: 12px;
  max-width: 195px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  @media only screen and (min-width:765px) {
    max-width: unset;
    font-size: 20px;
    line-height: 28px;
    margin-top: 36px;
  }
}

.prev,
.next {
  width: 8px;
  height: 20px;
  border: none;
  background: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 36%;
  z-index: 100;
  background-image: url(../../assets/icons/arrowRight.svg);
  transform: rotateY(180deg);
  // left: 15px;
  left: 0;

  &:hover {
    opacity: 0.95;
  }

  @media only screen and (min-width: 765px) {
    background-image: url(../../assets/icons/arrowRightBig.svg);
    width: 16px;
    height: 40px;
    // left: 28px;
  }

}

.next {
  // transform: translateY(-50%) rotate(0deg);
  transform: unset;
  left: unset;
  // right: 15px;
  right: 0;

  @media only screen and (min-width: 765px) {
    // right: -28px;
  }
}

:global(.swiper-pagination) {
  margin-top: 0;
  position: unset;
}

:global(.swiper-pagination-bullet) {
  width: 10px !important;
  height: 10px !important;
  background-color: #fff !important;
  border-radius: 50% !important;
  margin: 0 7.5px !important;
}