@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black)
}

.content {
  @include mixins.content($main-black);
  margin-top: 35px;
  margin-bottom: 53px;

  @media only screen and (min-width: 615px) {
    margin-top: 46px;
    margin-bottom: 53px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 85px;
    margin-bottom: 28px;
  }
}

.name {
  align-self: center;
  // margin-top: 35px;
  width: 100%;

  @media only screen and (min-width: 615px) {
    // margin-top: 46px;
    grid-row: 1;
    grid-column: 1/ span 12;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;


  @media only screen and (min-width: 615px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto auto auto auto;
    // row-gap: 40px;
    row-gap: 17px;
    column-gap: 5px;
    // margin-top: 30px;
    // margin-bottom: 40px;
  }

  @media only screen and (min-width: 1108px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    column-gap: 50px;
    row-gap: 17px;
  }
}

.subtitle {
  align-self: flex-start;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #fff;
  margin: 15px 0 0 0;
  text-align: left;
  width: 90%;
  letter-spacing: normal;

  @media only screen and (min-width: 615px) {
    margin-top: 9px;
    font-size: 25px;
    line-height: 35px;
    grid-row: 2;
    grid-column: 1/ span 12;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    margin-top: 0;

  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  list-style: none;
  padding: 0;
  width: 100%;
  letter-spacing: normal;

  @media only screen and (min-width: 615px) {
    grid-row: 3;
    grid-column: 1 / span 5;
    width: 100%;
    margin-bottom: 0;
    margin-top: 0;
    gap: 20px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 20px;
    grid-column: 1;
  }

}

.listItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
  text-align: left;
  padding-left: 22px;
  position: relative;

  @media only screen and (min-width:615px) {
    font-size: 20px;
    line-height: 28px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 12px;
    height: 12px;
    background-image: url(../../assets/icons/list-bullet.svg);
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (min-width:615px) {
      top: 7px;
    }
  }

}

.mac {
  max-height: 300px;
  max-width: 100%;
  display: block;
  width: 88%;
  height: auto;
  object-fit: contain;
  pointer-events: none;

  @media only screen and (min-width: 615px) {
    width: 100%;
    grid-row: 3 / span 2;
    grid-column: 6 / span 7;
    justify-self: end;
    margin-bottom: -28px;
    align-self: end;
    max-height: unset;
    max-width: unset;

  }

  @media only screen and (min-width: 1108px) {
    grid-row: 1 / span 5;
    grid-column: 2;
    width: 96%;
  }
}

.learnMore {
  @include mixins.button(0, 0);
  width: fit-content;
  text-decoration: none;
  letter-spacing: normal;

  @media only screen and (min-width: 615px) {
    grid-row: 4;
    grid-column: 1 / span 5;
    // margin-top: 16px;
    align-self: flex-start;
    margin-top: 5px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 15px;
    grid-column: 1;
  }
}