@font-face {
  src: url(../fonts/Roboto-Light.woff) format('woff');
  font-family: "Roboto";
  font-weight: 300;
}

@font-face {
  src: url(../fonts/Roboto-Regular.woff) format('woff');
  font-family: "Roboto";
  font-weight: 400;
}

@font-face {
  src: url(../fonts/Roboto-Medium.woff) format('woff');
  font-family: "Roboto";
  font-weight: 600;
}

@font-face {
  src: url(../fonts/Roboto-Bold.woff) format('woff');
  font-family: "Roboto";
  font-weight: 700;
}