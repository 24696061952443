@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
}

.content {
  @include mixins.content($main-black);
  margin-top: 35px;
  margin-bottom: 53px;

  @media only screen and (min-width:765px) {
    margin-top: 50px;
    margin-bottom: 131px;
  }

  @media only screen and (min-width:1108px) {
    margin-bottom: 140px;
  }
}

.title {
  @include mixins.title(0, #fff);
  align-self: flex-start;
  width: 100%;


  @media only screen and (min-width:765px) {
    max-width: 100%;
  }

  @media only screen and (min-width: 1108px) {}
}


.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 385px));
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  @media only screen and (min-width: 1108px) {
    gap: 39px;
    // margin-bottom: 150px;
  }
}

.card {

  padding: 30px 45px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  max-height: 434px;
  height: 100%;
  width: 100%;

  @media only screen and (min-width:765px) {}

  @media only screen and (min-width: 1108px) {
    padding: 41px 89px 54px;
  }
}

.cardText {

  text-align: center;
  margin: 0;
  padding: 0;

  &_black {
    color: $main-black;
  }

  &_title {
    // font-size: 18px;
    font-size: 25px;
    align-self: center;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: -0.02em;
    max-width: 202px;

    @media only screen and (min-width:765px) {
      font-size: 30px;
    }

  }

  &_type {
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.02em;
  }
}

.button {
  @include mixins.button(60px, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13.5px 24px;
  max-width: 207px;
  align-self: center;
  text-decoration: none;

  @media only screen and (min-width:765px) {
    // margin-top: 123px;
    margin-top: 186px;
  }

  &_white {
    background-color: #fff;
    border: 1px solid $main-blue;
    // margin-top: 13px;
    // margin-top: auto;
    color: $main-black;
    transition: opacity .2s ease-in-out;

    &:hover,
    &:active {
      background-color: #fff;
      opacity: .7;
    }
  }
}

.icon {
  display: block;
  background-position: center;
  background-repeat: no-repeat;

  &_download {
    background-image: url(../../assets/icons/download.svg);
    width: 10px;
    height: 16px;
    margin-left: 10px;
  }

  &_eye {
    background-image: url(../../assets/icons/eye.svg);
    width: 22px;
    height: 14px;
    margin-left: 10px;
  }
}

.accordion {
  background-color: $input-gray;
  width: 100%;
  text-align: left;
  color: #fff;
  // padding: 0 20px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 40px;

  @media only screen and (min-width:765px) {
    // padding: 0 40px;
  }

}

.trigger {
  background-color: $input-gray;
  // padding: 0 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;

  @media only screen and (min-width:765px) {
    font-size: 30px;
  }
}

.triggerSpan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 21px 20px;

  @media only screen and (min-width:765px) {
    padding: 21px 40px;
  }
}

.expand {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/icons/expand.svg);
  width: 33px;
  height: 14px;
  transition: transform .2s ease-in-out;

  &_opened {
    transform: rotateX(180deg);
  }
}

.innerContainer {
  cursor: pointer;
  padding: 0 20px 54px 20px;

  @media only screen and (min-width:765px) {
    padding: 0 40px 54px 40px;
  }
}

.accordionText {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  pointer-events: none;

  @media only screen and (min-width:765px) {
    font-size: 18px;
  }

}

.accordionList {
  pointer-events: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-top: 4px;
  margin-bottom: 0;
  list-style: none;
  padding: 0;

  @media only screen and (min-width:765px) {
    font-size: 18px;
  }

  &_last {
    margin-top: 0;
    // margin-bottom: 54px;
    padding-left: 10px;
    list-style: '- ';
  }
}