@import '../../variables';

.button {
  background-image: url(../../assets/icons/toTop.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: $main-blue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  border: none;
  transition: opacity .3s ease-in-out, background-color .2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: $main-blue-dark;
  }

  &_invisible {
    pointer-events: none;
    opacity: 0;
    cursor: default;
  }

  @media only screen and (min-width:1108px) {
    bottom: 40px;
    right: 104px;
  }
}