@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
}

.content {
  @include mixins.content($main-black);

  @media only screen and (min-width: 765px) {
    margin-top: 108px;
  }

  @media only screen and (min-width: 1108px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    // row-gap: 38px;
    margin-top: 76px;
    margin-bottom: 81px;
  }
}

.logo {
  margin-top: 54px;
  width: 59px;
  height: 59px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;

  &:hover,
  &:active {
    opacity: .9;
  }

  @media only screen and (min-width: 765px) {
    width: 116px;
    height: 116px;
    margin-top: 0;
  }

  @media only screen and (min-width: 1108px) {
    width: 66px;
    height: 66px;
    grid-column: 1;
    grid-row: 1;
    margin-top: 0;
  }
}

.text {
  @include mixins.text(39px, 0, #fff);
  text-align: center;
  max-width: 260px;
  display: block;

  @media only screen and (min-width: 765px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    max-width: 479px;
    margin-top: 44px;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    grid-row: 2;
    margin: 0;
    text-align: left;
    max-width: 247px;
  }

  &_link {
    margin-top: 13px;

    @media only screen and (min-width: 765px) {
      margin-top: 17px;
    }

    @media only screen and (min-width: 1108px) {
      grid-column: 2;
      grid-row: 4;
      text-align: right;
      margin-left: auto;
      max-width: unset;
      margin-top: 10px;
    }
  }

  &_contacts {
    grid-column: 2;
    grid-row: 3;

    @media only screen and (min-width: 1108px) {
      margin-bottom: 15px;
    }
  }

  &_address {
    margin-top: 30px;
  }
}

.link {
  text-decoration: none;
  color: #fff;
  transition: color .2s ease-in-out;

  &:hover,
  :active {
    color: $main-orange;
  }

}

.button {
  @include mixins.button(76px, 0);
  font-size: 16px;
  padding: 13.5px 24px;
  letter-spacing: -0.04em;

  @media only screen and (min-width: 765px) {
    margin-top: 92px;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 2;
    grid-row: 2;
    margin: auto 0 auto auto;
    width: fit-content;
  }
}

.copyright {
  @include mixins.text(71px, 53px, #fff);
  text-align: center;


  @media only screen and (min-width: 765px) {
    margin: 85px 0;
    font-size: 20px;
    line-height: 23.4px;
    letter-spacing: -0.02em;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    grid-row: 4;
    margin: 0;
    text-align: left;
    align-self: flex-end;
    margin-top: 10px;
  }
}