@use '../../mixins';
@import '../../variables';


.hamburgerMenu {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #091827E5;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform .3s ease-in-out;
  backdrop-filter: blur(10px);
  padding: 46px 32px;
  box-sizing: border-box;
  transform: translateX(100%);
  overflow: scroll;
  align-items: center;
  z-index: 10;

  @media only screen and (min-width: 1440px) {
    display: none;
  }

  &_active {
    transform: translateX(0)
  }
}

.close {
  @include mixins.button-with-icon(15px, 15px, '../../assets/icons/close.svg');
  align-self: flex-end;
}

.name {
  align-self: center;
  margin-top: 53px;
}

.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 55px 0 0 0;
  padding: 0;
  list-style: none;
  gap: 25px;
}


.link {
  text-decoration: none;
  color: #fff;
  transition: color .2s ease-in-out;
  cursor: pointer;

  &:hover,
  :active {
    color: $main-orange;
  }

  &_phone {
    margin-top: 73px;
    display: flex;
    align-items: center;
  }
}

.button {
  @include mixins.button(51px, 0);
  padding: 13.5px 24px;
  font-size: 16px;
  width: fit-content;

}

.text {
  @include mixins.text(39px, 0, #fff);
  text-align: center;
  max-width: 260px;
  display: block;
}

.phone {
  display: block;
  background-image: url(../../assets/icons/phone.svg);
  width: 11px;
  height: 13px;
  margin-right: 7px;
}