@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container();
  position: relative;
  overflow: hidden;

  // &:after {
  //   content: url(../../assets/images/lines.svg);
  //   position: absolute;
  //   bottom: -154px;
  //   left: 0;
  //   z-index: 0;
  //   pointer-events: none;
  //   display: none;

  //   @media only screen and (min-width: 1108px) {
  //     display: block;
  //   }
  // }

}

.content {
  @include mixins.content();
  margin-top: 48px;
  margin-bottom: 40px;

  @media only screen and (min-width: 765px) {
    margin: 98px 0 94px;
  }

  @media only screen and (min-width: 1108px) {
    flex-direction: row;
    margin-top: 140px;
    margin-bottom: 168px;
    gap: 40px;
  }
}

.title {
  @include mixins.title(0px);
  z-index: 1;
  max-width: 485px;

  @media only screen and (min-width:765px) {
    @include mixins.title(0px);

  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
  }
}

.accent {
  @include mixins.accent;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 19px;
  grid-column: 1;
  align-self: start;

  @media only screen and (min-width:765px) {
    gap: 15px;
    margin-top: 29px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 40px;
    gap: 14px;
  }
}

.informationContainer {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 765px) {
    // margin-top: 46px;
    grid-row: 1;
    grid-column: 1/3;
  }

  @media only screen and (min-width: 1108px) {
    align-self: flex-start;
    width: 50%;
  }
}

.text {
  z-index: 1;
  @include mixins.text(0, 0);
  align-self: flex-start;
  font-size: 18px;
  // letter-spacing: normal;
  letter-spacing: 0em;
  line-height: 25px;


  @media only screen and (min-width: 765px) {}

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    max-width: 545px;

  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 19px;
  // margin-bottom: 53px;
  margin-top: 20px;
  width: 100%;

  @media only screen and (min-width: 765px) {
    margin-top: 40px;
    column-gap: 41px;
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 2;
    grid-row: 1 / span 3;
    column-gap: 39px;
    width: 50%;
    margin-top: -35px;
  }


}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  @media only screen and (min-width: 765px) {
    gap: 29px;
  }

  // @media only screen and (min-width: 1108px) {
  //   gap: 29px;
  // }

  &_first {
    grid-column: 1;
  }

  &_second {
    grid-column: 2;
  }
}

.addresses {
  z-index: 1;
  width: 100%;
  pointer-events: none;
}

.analytics {
  z-index: 1;
  width: 100%;
  pointer-events: none;
}

.adresses {
  z-index: 1;
  grid-row: 1;
  grid-column: 2;
  pointer-events: none;
}

.adresses {
  z-index: 1;
  grid-row: 1;
  grid-column: 2;
  pointer-events: none;
}

.button {
  z-index: 1;
  @include mixins.button(40px, 0);
  display: none;
  text-decoration: none;
  letter-spacing: normal !important;

  @media only screen and (min-width:765px) {
    @include mixins.button(40px, 0);
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    max-width: fit-content;
    margin-top: 0;
    margin-bottom: 0;
    align-self: start;
    margin-top: 40px;
    display: block;
  }

  &_mobile {
    display: block;

    @media only screen and (min-width: 1108px) {
      display: none;
    }
  }
}