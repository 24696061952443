@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
  position: relative;

  &:before {
    pointer-events: none;

    @media only screen and (min-width: 765px) {
      content: url(../../assets/icons/decoration.svg);
      position: absolute;
      top: -85px;
      left: -18px;
    }

    @media only screen and (min-width: 1108px) {
      top: 654px;
      left: -19px;
    }
  }
}

.content {
  @include mixins.content($main-black);

  @media only screen and (min-width: 1108px) {
    margin-top: 107px;
    margin-bottom: 97px;
  }
}

.title {
  @include mixins.title(48px, #fff);
  max-width: 100% !important;
  text-align: center !important;
  width: 100%;

  @media only screen and (min-width:764px) {
    margin-top: 89px;
    font-size: 40px;
    line-height: 47px;
  }

  @media only screen and (min-width: 1108px) {
    grid-row: 1;
    grid-column: 1 / 3;
    margin-top: 0;
  }
}

.accent {
  @include mixins.accent
}

.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 44px;
  margin-top: 48px;
  column-gap: 15px;
  justify-content: space-evenly;
  width: 100%;

  @media only screen and (min-width:765px) {
    margin-top: 40px;
  }

  @media only screen and (min-width:1108px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    column-gap: 34px;
    row-gap: 122px;
    grid-auto-flow: column;
    margin-top: 88px;
  }
}

.functionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-height: fit-content;
  // max-width: 310px;
  max-width: 100%;


  @media only screen and (min-width:665px) {
    max-width: 310px;
  }

  @media only screen and (min-width:765px) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media only screen and (min-width:1108px) {
    align-items: center;
    max-width: 432px;

    &_analytics {
      grid-column: 1;
      grid-row: 1;
    }

    &_monitoring {
      grid-column: 2;
      grid-row: 1;
    }

    &_management {
      grid-column: 3;
      grid-row: 1;
    }

    &_fiscalization {
      display: none;
    }
  }

}

.fiscalizationContainer {
  display: none;

  @media only screen and (min-width:1108px) {
    display: flex;
    grid-column: 1/span 3;
    grid-row: 2;
  }
}

.fiscalizationTextContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 50%;
  align-self: flex-end;
}

.fiscalizationImage {
  margin-top: 0;
  pointer-events: none;
  width: 100%;
  max-width: 520px;
  align-self: flex-end;
  margin-left: auto;
  // max-width: 574px !important;
}

// .fiscalizationText{
//    @include mixins.text(11px, 0, #fff);
//   font-size: 18px;
//   line-height: 25.2px;
//   letter-spacing: 0;

//   @media only screen and (min-width:765px) {
//     font-size: 18px;
//   }

// }

.subtitle {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: center;
  color: #fff;
  margin: 0;
  letter-spacing: 0;
  margin-top: 18px;

  @media only screen and (min-width: 1108px) {
    font-size: 20px;
    line-height: 28px;
    margin-top: 42px;
  }

  &_fiscalization {
    @media only screen and (min-width: 1108px) {
      margin-top: 32px;
      align-self: flex-start;
    }
  }

}

.text {
  @include mixins.text(11px, 0, #fff);
  font-size: 18px;
  line-height: 25.2px;
  letter-spacing: 0;
  max-width: 100%;

  @media only screen and (min-width:765px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 1108px) {
    &_small {
      text-align: center;
    }

    margin-top: 16px;
  }

  &_fiscalization {
    margin-top: 0;
  }
}

.image {
  margin-top: 0;
  grid-row: 2;
  grid-column: 2;
  // max-width: 202px;
  width: 100%;
  pointer-events: none;
  height: 100%;

  @media only screen and (min-width: 1108px) {
    max-width: 100%;
    margin-top: 0;
    margin-left: auto;
  }
}

.imageContainer {
  height: 100%;
  max-height: 182px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;

  @media only screen and (min-width:665px) {
    max-height: 200px;
  }

  @media only screen and (min-width:1108px) {
    max-height: 282px;
  }
}

.button {
  @include mixins.button(47px);
  // margin-bottom: 97px;
  max-width: fit-content;
  align-self: center;
  text-decoration: none;
  letter-spacing: normal;

  &_mobile {
    @media only screen and (min-width: 1108px) {
      display: none;
    }
  }

  @media only screen and (min-width: 765px) {
    margin-bottom: 100px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 34px;
    margin-bottom: 0;
    align-self: flex-start;
  }

  &_fiscalization {
    display: none;

    @media only screen and (min-width: 1108px) {
      display: block;
      margin-top: 40px;
    }
  }
}

.note {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  @media only screen and (min-width:1108px) {
    display: none;
  }
}

.noteIcon {
  display: block;
  background-image: url(../../assets/icons/note.svg);
  width: 21px;
  height: 21px;
  background-position: center;
  background-repeat: no-repeat;
  justify-self: flex-start;
}