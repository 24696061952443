@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container();
}

.content {
  @include mixins.content();

  @media only screen and (min-width: 1108px) {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin: 112px 0;
  }
}

.title {
  @include mixins.title(54px, $main-black);

  @media only screen and (min-width:765px) {
    margin-top: 89px;
    max-width: 100%;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 0;
  }
}

.accent {
  @include mixins.accent
}

.text {
  @include mixins.text(19px, 0);
  align-self: flex-start;

  &:last-of-type {
    margin-top: 15px;
    margin-bottom: 28px;

    @media only screen and (min-width:765px) {
      margin-top: 14px;
      line-height: 25px;
    }

    @media only screen and (min-width:1108px) {
      margin-top: 10px;
    }
  }

  @media only screen and (min-width:765px) {
    margin-top: 33px;
    font-size: 18px;
  }
}

.textContainer {
  @media only screen and (min-width: 1108px) {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: 100%;
    align-self: flex-start;
  }

}

.payments {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 16px;
  margin-bottom: 53px;
  width: 100%;

  @media only screen and (min-width:765px) {
    margin-bottom: 95px;
    margin-top: 15px;
    row-gap: 30px;
  }

  @media only screen and (min-width: 1108px) {
    margin-bottom: 0;
    max-width: 50%;
    width: 100%;
    // flex-shrink: 0;
    margin-top: 2px;
    column-gap: 37px;
  }
}

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid $main-blue;
  border-radius: 5px;
  min-width: 137px;
  width: 100%;
  height: 137px;
  box-sizing: border-box;

  @media only screen and (min-width:765px) {
    display: grid;
    grid-template-columns: 4fr 6fr;
    // padding-left: 25px;
    height: 105px;
  }

  &_cash {
    grid-column: 1;
    grid-row: 1;
  }

  &_qr {
    grid-column: 2;
    grid-row: 1;
  }

  &_card {
    grid-column: 1;
    grid-row: 2;
  }

  &_pin {
    grid-column: 2;
    grid-row: 2;
  }

  &_sbp {
    grid-column: 1;
    grid-row: 3;
  }

  &_kaspi {
    grid-column: 2;
    grid-row: 3;
  }
}

.paymentName {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  text-align: center;

  @media only screen and (min-width:765px) {
    margin: 0 !important;
    max-width: 141px;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    grid-column: 2;
    margin-left: -4px !important;
  }

  @media only screen and (min-width:1108px) {

    margin-left: 4px !important;
  }

  &_cash {
    margin-top: 12px;
  }

  &_qr {
    margin-top: 12px;
  }

  &_card {
    margin-top: 10px;
  }

  &_pin {
    margin-top: 12px;
  }

  &_sbp {
    margin-top: 18px;

    @media only screen and (max-width:764px) {
      display: none;
    }
  }

  &_mobile {
    display: none;

    @media only screen and (max-width:764px) {
      display: block;
    }
  }

  &_kaspi {
    margin-top: 10px;
  }

}

.image {
  grid-column: 1;
  pointer-events: none;

  @media only screen and (min-width:765px) {
    margin-left: 25px;
  }
}