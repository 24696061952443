@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container();
  position: relative;
  overflow: hidden;

  &:after {
    content: url(../../assets/images/lines.svg);
    position: absolute;
    bottom: -154px;
    right: 50%;
    z-index: 0;
    pointer-events: none;
    display: none;

    @media only screen and (min-width: 1108px) {
      display: block;
    }
  }

}

.content {
  @include mixins.content();
  margin-top: 0;
  margin-bottom: 53px;

  @media only screen and (min-width: 765px) {
    margin-bottom: 106px;
  }

  @media only screen and (min-width: 1108px) {
    // flex-direction: row;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // margin-top: 140px;
    margin-bottom: 202px;
    column-gap: 35px;
  }
}

.title {
  @include mixins.title(0px);
  z-index: 1;
  width: 100%;

  @media only screen and (min-width:765px) {
    max-width: unset;
  }

  @media only screen and (min-width: 1108px) {
    // grid-column: 1;
  }
}

.accent {
  @include mixins.accent;
}

.textContainer {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1108px) {
    grid-column: 2;
    grid-row: 1;
    align-self: start;
  }
}

.text {
  z-index: 1;
  @include mixins.text(0, 0);
  align-self: flex-start;
  font-size: 18px;
  // letter-spacing: normal;
  letter-spacing: 0em;
  line-height: 25px;
  margin-top: 20px;


  @media only screen and (min-width: 765px) {
    margin-top: 30px;

    &:last-of-type {
      margin-top: 40px;
    }
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 40px;
    // max-width: 545px;
  }
}

.list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  @media only screen and (min-width:765px) {
    margin-top: 20px;
    gap: 19px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 20px;
    width: 100%;
  }
}

.listItem {
  position: relative;
  padding-left: 25px;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  // line-height: 25.2px;
  letter-spacing: 0;


  &::before {
    content: url(../../assets/icons/marker.svg);
    position: absolute;
    left: 0;
    top: -4px;
    font-size: 25px;
    font-weight: 700;
    line-height: 29px;
    color: $main-orange;
  }

  @media only screen and (min-width:765px) {
    font-size: 18px;
    padding-left: 25px;
    line-height: 25.2px;


    &::before {
      content: url(../../assets/icons/marker.svg);
      height: 12px;
      top: -5px;
      left: -3px;
    }
  }

  @media only screen and (min-width:1108px) {
    // max-width: 495px;
  }
}

.certificate {
  width: 100%
}

.certificateContainer {
  object-fit: contain;
  margin-top: 39px;
  max-width: 398px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #0000000F;
  max-height: 564px;


  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    grid-row: 1;
    margin-top: 0;
    align-self: center;
    justify-self: center;
    z-index: 1;
    height: 99%;
  }
}

.button {
  z-index: 1;
  @include mixins.button(33px, 0);
  display: none;
  text-decoration: none;
  letter-spacing: normal !important;

  @media only screen and (min-width:765px) {
    @include mixins.button(40px, 0);
  }

  @media only screen and (min-width: 1108px) {
    grid-column: 1;
    max-width: fit-content;
    margin-top: 0;
    margin-bottom: 0;
    align-self: start;
    margin-top: 40px;
    display: block;
  }

  &_mobile {
    display: block;

    @media only screen and (min-width: 1108px) {
      display: none;
    }
  }
}