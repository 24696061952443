@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container($main-black);
  overflow: hidden;
  position: relative;
}

.content {
  @include mixins.content($main-black);
  margin-top: 23px;
  flex-direction: row;

  @media only screen and (min-width: 765px) {
    margin-top: 54px;
  }
}

.logo {
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition: opacity .2s ease-in-out;

  &:hover,
  &:active {
    opacity: .9;
  }

  @media only screen and (min-width: 765px) {
    width: 54px;
    height: 54px;
  }
}

.hamburger {
  @include mixins.button-with-icon(40px, 21px, '../../assets/icons/hamburger.svg');

  @media only screen and (min-width: 1108px) {
    display: none;
  }
}

@media only screen and (max-width: 1107px) {
  .navigation {
    display: none;
  }

  .links {
    display: none;
  }


  .link {
    display: none;
  }

  .button {
    display: none;
  }

  .phone {
    display: none;
  }
}

@media only screen and (min-width: 1108px) {
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .links {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    list-style: none;
    gap: 15px;
    margin: 0;
    margin-left: 20px;

    @media only screen and (min-width: 1440px) {
      gap: 59px;
    }
  }


  .link {
    text-decoration: none;
    color: #fff;
    transition: color .2s ease-in-out;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

    @media only screen and (max-width:1200px) {
      font-size: 16px;
    }

    &:hover,
    :active {
      color: $main-orange;
    }

    &_phone {
      margin-left: auto;
      margin-right: 42px;
      display: flex;
      align-items: center;
    }

  }

  .button {
    @include mixins.button(0, 0);
    border: 1px solid $main-orange;
    color: $main-orange;
    background-color: transparent;
    padding: 13.5px 24px;
    font-size: 16px;
    width: fit-content;
    transition: color .3s ease-in-out, border-color .3s ease-in-out;

    &:hover,
    &:active {
      border-color: #fff;
      color: #fff;
      background-color: transparent;
    }
  }



  .phone {
    display: block;
    background-image: url(../../assets/icons/phone.svg);
    width: 11px;
    height: 13px;
    margin-right: 7px;
  }
}

@media only screen and (min-width: 1300px) {
  .links {
    margin-left: 98px;
  }

}