@use '../../mixins';
@import '../../variables';

.container {
  @include mixins.container();
}

.content {
  @include mixins.content();
}

.title {
  @include mixins.title(48px, $main-black);
  max-width: 60%;
  align-self: flex-start;

  @media only screen and (min-width:765px) {
    margin-top: 92px;
  }

  @media only screen and (min-width: 1108px) {
    margin-top: 140px;
  }
}

.accent {
  @include mixins.accent
}

.text {
  @include mixins.text(15px, 0, #fff);

  &:first-of-type {
    margin-top: 41px;
  }
}

.list {
  list-style-type: none;
  counter-reset: custom-counter;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;

  @media only screen and (min-width:765px) {
    margin-top: 31px;
    gap: 53px;
  }

  @media only screen and (min-width: 1108px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    row-gap: 35px;
    column-gap: 43px;
    margin-top: 72px;
    width: 100%;
  }
}

.listItem {
  counter-increment: custom-counter;
  position: relative;
  padding-left: 25px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;

  &_one {
    grid-row: 1;
    grid-column: 1;
  }

  &_two {
    grid-row: 2;
    grid-column: 1;
  }

  &_three {
    grid-row: 3;
    grid-column: 1;
  }

  &_four {
    grid-row: 4;
    grid-column: 1;
  }

  &_five {
    grid-row: 1;
    grid-column: 2;
  }

  &_six {
    grid-row: 2;
    grid-column: 2;
  }

  &_seven {
    grid-row: 3;
    grid-column: 2;
  }

  &_eight {
    grid-row: 4;
    grid-column: 2;
  }


  &::before {
    content: counter(custom-counter);
    position: absolute;
    left: 0;
    font-size: 25px;
    font-weight: 700;
    line-height: 29px;
    color: $main-orange;
  }

  @media only screen and (min-width:765px) {
    font-size: 18px;
    padding-left: 35px;
    line-height: 25.2px;


    &::before {
      content: counter(custom-counter);
      font-size: 35px;
      top: 2px;
    }
  }

  @media only screen and (min-width:1108px) {
    max-width: 495px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  z-index: 0;
  position: relative;

  &:before {
    content: url(../../assets/images/form-decoration-before.svg);
    position: absolute;
    top: -16px;
    left: -14px;
    z-index: -1;

    @media only screen and (min-width:765px) {
      top: -30px;
      left: -35px;
      content: url(../../assets/images/form-decoration-before-tablet.svg);
    }
  }

  &::after {
    content: url(../../assets/images/form-decoration-after.svg);
    position: absolute;
    top: -11px;
    left: -10px;
    z-index: 0;

    @media only screen and (min-width:765px) {
      top: -20px;
      left: -26px;
      content: url(../../assets/images/form-decoration-after-tablet.svg);
    }
  }

  @media only screen and (min-width: 1108px) {
    flex-direction: row;
  }
}

.formHeader {
  background: linear-gradient(156.71deg, #3934EA 1.43%, #2D28CE 40.2%, #6360CA 99.47%);
  width: 100%;
  padding: 0 28px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;

  @media only screen and (min-width:765px) {
    font-size: 40px;
    line-height: 47px;
    letter-spacing: -0.02em;
    text-align: left;
    padding-left: 66px;
    // padding-right: 38px;
  }

  @media only screen and (min-width: 1108px) {
    max-width: 50%;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
  }
}

.formTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase;
  margin-top: 53px;
  display: block;
  text-align: left;

  @media only screen and (min-width:765px) {
    margin-top: 90px;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: -0.02em;

  }

  @media only screen and (min-width:1108px) {
    margin-top: 75px;
  }
}

.formText {
  @include mixins.text(20px, 49px, #fff);
  line-height: 19px;

  &_gray {
    color: $main-gray;
    font-size: 14px;
    margin-top: 18px;
    margin-bottom: 0;
    line-height: 16.38px;
    letter-spacing: -0.02em;

  }

  @media only screen and (min-width:765px) {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.02em;
    margin-bottom: 92px;
    margin-top: 30px;
    // max-width: 465px;

    &_gray {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 25px;
      margin-top: 17px !important;
      max-width: unset !important;
    }
  }

  @media only screen and (min-width:1108px) {
    max-width: 465px;
    margin-top: 28px;
  }
}

.formMain {
  background-color: $main-black;
  padding: 0 19px 0 29px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::after {
    content: url(../../assets/icons/rays.svg);
    position: absolute;
    bottom: -34px;
    right: -15px;

    @media only screen and (min-width:765px) {
      bottom: -56px;
      right: -32px;
      content: url(../../assets/images/rays-tablet.svg);
    }
  }

  @media only screen and (min-width: 765px) {
    padding-right: 69px;
    padding-left: 40px;
  }


  @media only screen and (min-width: 1108px) {
    max-width: 50%;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    padding-right: 72px;
  }
}

.input {
  background-color: $input-gray;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;

  &::placeholder {
    color: $second-gray;
  }

  @media only screen and (min-width:765px) {
    padding-left: 24px;
    font-size: 16px;
    line-height: 21px;
    height: 60px;
  }
}

.textArea {
  background-color: $input-gray;
  width: 100%;
  height: 121px;
  box-sizing: border-box;
  padding: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-family: 'Roboto';
  resize: none;

  @media only screen and (min-width:765px) {
    font-size: 18px;
    line-height: 21px;
    padding-left: 24px;
  }
}

.label {
  margin-top: 51px;
  display: block;


  &_comment {
    margin-top: 49px;
  }

  @media only screen and (min-width:765px) {
    margin-top: 30px;

    &:first-of-type {
      margin-top: 91px;
    }
  }

  @media only screen and (min-width: 1108px) {
    &:first-of-type {
      margin-top: 73px;
    }
  }
}

.labelText {
  @include mixins.text(0, 0, #fff);
  letter-spacing: -0.02em;
  width: 100%;
  display: block;
  margin-bottom: 15px;


  @media only screen and (min-width:765px) {
    font-size: 18px;
    line-height: 21px;
  }
}

.submit {
  @include mixins.button(40px, 92px);
  padding: 13.5px 24px;
  box-sizing: border-box;


  @media only screen and (min-width:765px) {
    @include mixins.button(0, 38px);
    padding: 13.5px 24px;
    margin-right: auto;
    margin-left: 0;
    max-width: unset;

  }

  @media only screen and (min-width: 1108px) {
    margin-bottom: 91px;
  }
}

.button {
  @include mixins.button(40px, 58px);
  text-decoration: none;
  letter-spacing: normal !important;

  @media only screen and (min-width:765px) {
    @include mixins.button(60px, 100px);
  }

  @media only screen and (min-width: 1108px) {
    @include mixins.button(60px, 122px);
  }
}

.notification {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: $main-black;
  justify-content: center;
  align-items: center;
  padding: 29px;
  box-sizing: border-box;

  &_invisible {
    display: none;
  }
}

.confirm {
  object-fit: contain;
  width: 53px;
}

.confirmText {
  font-size: 22px;
  line-height: 26px;

  letter-spacing: -0.02em;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  margin-top: 24px;

  &_small {
    margin-top: 14px;
    font-weight: 400;
    line-height: 23px;
    text-transform: unset;
    font-size: 16px;
    line-height: 19px;

  }


  @media only screen and (min-width:765px) {
    font-size: 40px;
    line-height: 47px;
    margin-top: 27px;

    &_small {
      margin-top: 14px;
      font-size: 20px;
      line-height: 23px;
    }

  }
}

.link {
  color: #fff;
}